import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './index.css';

class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgreed: false,
    };
  }

  handleCheckboxChange = () => {
    this.setState(prevState => ({
      isAgreed: !prevState.isAgreed
    }));
  }

  render() {
    return (
      <div id="popup" class="popup">
        <div class="popup-content">
        <h3>Claves para el Uso del Verificador de Fakes</h3>
        <p>Antes de utilizar la herramienta se presentan algunas claves imprescindibles para hacer un buen uso del verificador de fakes.</p>
        <h3>¿Qué es un fake de audio?</h3>
        <p>Los deepfakes son medios visuales o de audio manipulados o sintéticos que parecen auténticos, producidos mediante técnicas de inteligencia artificial, como el aprendizaje automático (machine learning) y el aprendizaje profundo (deep learning), muchas veces con fines maliciosos.<br/><br/>
        Trabajamos con dos tipos de audios falsos: <br/><br/>
          <b>Voz clonada:</b> Se genera una voz sintética que suena como la original a partir de grabaciones de la persona cuya voz se está clonando.<br/>
          <b>Voz manipulada:</b> Se trata de transformar la voz de una tercera persona para que suene como la voz objetivo.</p>
        <h3>¿Cómo está construido este detector de fakes?</h3>
        <p>Este detector utiliza inteligencia artificial para dar una predicción sobre si un audio es falso o no. Esto se consigue entrenando nuestro modelo para que “aprenda” ciertas características y patrones que presentan los audios cuando son falsos o verdaderos. Cuantos más audios, tanto fakes como reales, se utilicen en su entrenamiento, más fiable será la predicción.<br/><br/>
          Sin embargo, las técnicas para generar voces sintéticas son variadas y siguen evolucionando con la tecnología, generando audios falsos cada vez más realistas. Si se le proporciona al detector un fake creado con una técnica diferente a las que ha aprendido en su entrenamiento, es probable que arroje una predicción con una baja confianza o incluso una predicción errónea, con una alta confianza.<br/><br/>
          Si el periodista sospecha que el audio que quiere verificar se trata de una voz manipulada, es importante localizar el corte de interés, con una duración de pocos segundos. Si la herramienta recibe un fake de un minuto, en el que 58 segundos del audio es una voz real, con gran probabilidad lo clasificará como verídico.</p>
        <h3>¿Es infalible este detector de deep fakes?</h3>
        <p>Por estos motivos, a la hora de contrastar si un audio es o no un fake, es importante tener en cuenta que este detector <b>no es una fuente infalible.</b> Aunque la confianza del detector en su predicción sea muy alta, es imprescindible tener en cuenta que se trata tan solo de una estimación que puede servir al periodista como orientación. <b>En ningún caso debe tomarse la predicción del detector como una prueba definitiva, sino que siempre tiene que estar apoyada por otras fuentes que ratifiquen su veracidad o falsedad.</b></p>
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={this.state.isAgreed}
            onChange={this.handleCheckboxChange}
          />
          <label htmlFor="agree">He comprendido que las predicciones de este detector de deep fakes son solo orientativas, y que no deben tomarse en ningún caso como una garantía de su falsedad/veracidad, teniendo que respaldarse esta predicción por otras fuentes fiables.</label>
        </div>
          <button onClick={this.props.onClose} disabled={!this.state.isAgreed}>Aceptar</button>
        </div>
      </div>

      //<div className="popup">
      //  <p>Se están haciendo cambios en la página, ignora esta página y dale a aceptar.</p>
      //  <button onClick={this.props.onClose}>Aceptar</button>
      //</div>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      person: 'Otros',
      fakePercentage: null,
      uploadMessage: null,
      resultMessage: 'Fallo en el servidor',
      progressClass: '',
      username: '',
      password: '',
      loggedIn: false,
      isLoadingFastaudio: false,
      isLoadingLoccus: false, // Nuevo estado para indicar si se está cargando
      buttonPressed: '',
      isPopUpVisible: false,
      lastUpdated: '2024-04-09', // Añadir la fecha actual como ejemplo
      isUsabilityFormVisible: false, // Mostrar el formulario de usabilidad al cargar la página
      segmentPredictions: [],
      chunksData: [],
      audioURL: null,
      logoutTimer: null,
    };
  }

  componentDidMount() {
    this.handleAutoLogout();
  }

  handleAutoLogout = () => {
    const token = localStorage.getItem('access_token');
    if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
            this.logout();
        } else {
            const expirationTime = (decodedToken.exp * 1000) - Date.now();
            this.logoutTimer = setTimeout(() => {
                this.logout();
            }, expirationTime);
        }
    }
}

  componentWillUnmount() {
    clearTimeout(this.logoutTimer);
  }

  logout = () => {
    localStorage.removeItem('access_token');
    this.setState({ loggedIn: false });
  }

  closeUsabilityForm = () => {
    this.setState({ isUsabilityFormVisible: false });
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  }
  
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  }

  handleFileChange = (event) => {
    this.setState({ file: event.target.files[0] });
  }

  handlePersonChange = (event) => {
    this.setState({ person: event.target.value });
  }

  calculateFakePercentage = (number) => {
    if (number <= 0) {
      return 0;
    } else if (number >= 10) {
      return 100;
    } else {
      return (number / 10) * 100; // Ajuste para el nuevo extremo
    }
  }  

  calculateFakePercentage2 = (number) => {
    if (number <= 0) {
      return 0;
    } else if (number >= 20) {
      return 100;
    } else {
      return (number / 20) * 100;
    }
  }

  calculateFakePercentageLoccus = (number) => {
    if (number <= 0) {
      return 100;
    } else if (number >= 1) {
      return 100;
    } else if (number <= 0.7) {
      return (0.7 - number) * (100 / 0.7);
    } else {
      return (number - 0.71) * (100 / (1 - 0.71));
    }
  }

  handleSubmitLog = (event) => {
    event.preventDefault();
    
    const { username, password } = this.state;
    
    // Realizar solicitud POST a la API de Flask
    fetch('https://verificar-audio.iveres.es:5001/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    .then(response => response.json())
    .then(data => {
      if (data.access_token) {
        // Guardar el token de acceso en el estado o almacenamiento local
        localStorage.setItem('access_token', data.access_token);
        this.setState({ loggedIn: true, isPopUpVisible: true }, this.handleAutoLogout);
      } else {
        alert('Credenciales inválidas');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Error al iniciar sesión. Por favor, inténtelo de nuevo.');
    });
  }
  

  closePopUp = () => {
    this.setState({ isPopUpVisible: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { file, person } = this.state;

    const formData = new FormData();
    formData.append('file', file);

    if (this.state.buttonPressed === 'loccus') {
      this.setState({ isLoadingLoccus: true }); // Indicar que se está cargando
      //axios.post('http://3.255.21.243:5001/upload/loccus', formData)
      axios.post('https://verificar-audio.iveres.es:5001/upload/loccus', formData)
      .then(response => {
        const { score, chunks_data, audio_url  } = response.data;
        let resultMessage = 'Probablemente fake';
        let progressClass = 'probably-fake';
        if (score > 0.7) {
          resultMessage = 'Probablemente real';
          progressClass = 'probably-real';
        }

        // Calcular el valor de fakePercentage
        const fakePercentage = this.calculateFakePercentageLoccus(score);

        this.setState({ fakePercentage, uploadMessage: 'Archivo subido y procesado', resultMessage, progressClass, isLoadingLoccus: false, chunksData: chunks_data, audioURL: audio_url}, () => {
          // Usar un callback aquí para asegurarse de que el temporizador se establece después de que el estado ha sido actualizado
          setTimeout(() => {
            this.setState({ isUsabilityFormVisible: true });
          }, 5000); // Temporizador de 5 segundos
        });
      }).catch(error => {
        if (error.response && error.response.status === 413) {
          alert('El audio es demasiado largo. La longitud debe ser inferior a un minuto');
        } else if (error.response && error.response.status === 409) {
            alert('Nombre del audio existente en nuestra base de datos. Cambie el nombre del audio e inténtelo de nuevo');
        } else {
            alert(`Error: ${error.message}.`);
        }
        this.setState({ isLoadingLoccus: false });
	this.setState({ fakePercentage: null });
	this.setState({ uploadMessage: 'Error al procesar el audio' });
      });

    } else {
      this.setState({ isLoadingFastaudio: true }); // Indicar que se está cargando

      //let baseUrl = 'http://3.255.21.243:5001/upload/fastaudio/';
      let baseUrl = 'https://verificar-audio.iveres.es:5001/upload/fastaudio/';
      let personEndpoint = '';
      
      switch (person) {
        case 'Felipe VI':
          personEndpoint = 'FastaudioFelipe';
          break;
        case 'Yolanda Díaz':
          personEndpoint = 'FastaudioYolanda2';
          break;
        case 'Pedro Sanchez':
          personEndpoint = 'FastaudioPedro';
          break;
        case 'Otros':
          personEndpoint = 'FastaudioYolanda';
          break;
        default:
          // Manejar error o caso no esperado
          alert('Por favor, seleccione una persona válida.');
          return; // Salir del método si no se seleccionó una persona válida
        }
    
        const fullUrl = baseUrl + personEndpoint;    

        axios.post(fullUrl, formData).then(response => {
            const { number,audio_url } = response.data;
            let resultMessage = 'Probablemente fake';
            let progressClass = 'probably-fake';
            if (number > 0) {
            resultMessage = 'Probablemente real';
            progressClass = 'probably-real';
            }

            // Calcular el valor de fakePercentage
            const fakePercentage = this.calculateFakePercentage(Math.abs(number));

            this.setState({ fakePercentage, uploadMessage: 'Archivo subido y procesado', resultMessage, progressClass, isLoadingFastaudio: false, audioURL: audio_url}, () => {
              // Usar un callback aquí para asegurarse de que el temporizador se establece después de que el estado ha sido actualizado
              setTimeout(() => {
                this.setState({ isUsabilityFormVisible: true });
              }, 5000); // Temporizador de 5 segundos
            });
        }).catch(error => {
            alert(`Error: ${error.message}. ${error.error}`);
            this.setState({ isLoadingFastaudio: false });
            this.setState({ fakePercentage: null });
            this.setState({ uploadMessage: 'Error al procesar el audio' });
        });
      
    }
  }

  // Método para renderizar la barra de segmentos
  renderChunkSegments = () => {
    const { chunksData } = this.state;
  
    const segmentStyle = (score) => ({
      width: `${100 / chunksData.length - 1}%`, // Dividir el ancho en partes iguales
      backgroundColor: score >= 0.7 ? 'green' : '#e51443', // Verde si >= 0.7, Rojo si < 0.7
      height: '100%', // Altura completa de la barra de progreso
      display: 'inline-block', // Para que los segmentos se muestren uno junto a otro
      border: '1px solid black' // Borde para separar los segmentos
    });
  
    // Función para eliminar PT y S del formato de tiempo
    const cleanTime = (time) => time.replace(/PT|S/g, '');
  
    return chunksData.map((chunk, index) => {
      const startTime = cleanTime(chunk.startTime);
      const endTime = cleanTime(chunk.endTime);
      const tooltipText = `Este fragmento empieza en el segundo: ${startTime} y acaba en el segundo: ${endTime}`;
      return <div key={index} style={segmentStyle(chunk.score)} title={tooltipText}></div>;
    });
  }

  render() {
    const { file, person, fakePercentage, uploadMessage, resultMessage, progressClass } = this.state;

    const formattedPercentage = fakePercentage !== null ? `${fakePercentage.toFixed(2)}%` : '';

    const { loggedIn, isPopUpVisible } = this.state;

    const { lastUpdated } = this.state;
    const { isUsabilityFormVisible } = false;

    const { segmentPredictions, audioURL } = this.state;

    let additionalMessage = null;
    if (person === 'Otros') {
      additionalMessage = <p style={{ fontFamily: 'Exo Variable' }}>Tenga en cuenta que el clasificador está especializado en ciertas voces, al seleccionar 'Otros' el resultado puede ser menos fiable</p>;
    }

    if (loggedIn && isPopUpVisible) {
      return <PopUp onClose={this.closePopUp} />;
    } else if (loggedIn) {
      // Renderizar el contenido principal de la aplicación
      return (
        <div className="root">
          <header>
            <img src="./1-Horizontal.png" alt="logo" className="logo" />
            <img src="./iveres.png" alt="logo2" className="logo2" />
            <h6 className="title"></h6>
          </header>
          <div className="container">
            <div className="form-container">
              <div className="summary" >
                <p className='intro' style={{ fontFamily: 'Exo Variable' }}>A continuación 
                se presenta una demo de las capacidades de detección de 
                deep fake en audio desarrolladas en la Cátedra RTVE-UGR 
                en colaboración con el proyecto IVERES</p>
              </div>
              <form onSubmit={this.handleSubmit}>
                <p style={{ fontFamily: 'Exo Variable' }}>
                  <a href="/Manual.pdf" download>Descargar Manual de Usuario (PDF)</a>
                </p>

                <label id="padre" className="subir-text" style={{ fontFamily: 'Exo Variable' }} htmlFor="file">
                  Subir audio:
                </label>

                <div className="form-container">
                  <input type="file" id="file" className="custom-file-input" onChange={this.handleFileChange} required />
                  <label htmlFor="file" className="custom-file-input-display"></label>
                </div>

                <div className="custom-select-container">
                  <select id="person" value="Otros" onChange={this.handlePersonChange} required={this.state.buttonPressed !== 'loccus'}>
                    <option value="">Seleccione persona</option>
                    <option value="Felipe VI">Felipe VI</option>
                    <option value="Yolanda Díaz">Yolanda Díaz</option>
                    <option value="Pedro Sanchez">Pedro Sánchez</option>
                    <option value="Otros">Otros</option>
                  </select>
                </div>
                <div className='modelos'>
                <button type="submit" id='fastaudio' title="Con este botón comprobamos la falsedad o veracidad del audio con la herramienta generada en la Cátedra UGR-RTVE" onClick={() => this.setState({ buttonPressed: 'fastaudio' })} disabled={this.state.isLoadingFastaudio}>
                  {this.state.isLoadingFastaudio ? 'Cargando...' : 'Verificar con Fastaudio'}
                </button>

                <button type="submit" id='loccus' title="Con este botón comprobamos la falsedad o veracidad del audio con la herramienta de la empresa Loccus.ai" onClick={() => this.setState({ buttonPressed: 'loccus' })} disabled={this.state.isLoadingLoccus}>
                 {this.state.isLoadingLoccus ? 'Cargando...' : 'Verificar con Loccus'}
                </button>
                </div>
                {uploadMessage && !this.state.isLoadingFastaudio && this.state.buttonPressed == 'fastaudio' && (
                  <p style={{ fontFamily: 'Exo Variable' }}>{uploadMessage}</p>
                )}

                {uploadMessage && !this.state.isLoadingLoccus && this.state.buttonPressed == 'loccus' && (
                  <p style={{ fontFamily: 'Exo Variable' }}>{uploadMessage}</p>
                )}
              </form>

              

              <div className="results-container">
                {fakePercentage !== null && !this.state.isLoadingFastaudio && this.state.buttonPressed == 'fastaudio' && (
                  <div className="fake-percentage">
                    <p className='subir-text' style={{ fontFamily: 'Exo Variable' }}>
                      Esta predicción ha sido realizada con el modelo de la Cátedra RTVE-UGR (Fastaudio)
                    </p>
                    <p className='subir-text' style={{ fontFamily: 'Exo Variable' }}>
                      {resultMessage} (confianza en la predicción: {formattedPercentage})
                    </p>

                    <div class="progress-bar">
                      <div class={`${progressClass}`} style={{ width: formattedPercentage }}></div>
                    </div>

                    <audio controls className="audio-container">
                      <source src={`${audioURL}`} type="audio/mpeg" />
                    </audio>

                    {person === 'Otros' && (
                      <p style={{ fontFamily: 'Exo Variable' }}>
                        Tenga en cuenta que el clasificador está especializado en ciertas voces, al seleccionar 'Otros' el resultado puede ser menos fiable
                      </p>
                    )}
                  </div>
                )}

                {fakePercentage !== null && !this.state.isLoadingLoccus && this.state.buttonPressed == 'loccus' && (
                  <div className="fake-percentage">
                    <p className='subir-text' style={{ fontFamily: 'Exo Variable' }}>
                      Esta predicción ha sido realizada con el modelo de Loccus
                    </p>
                    <p className='subir-text' style={{ fontFamily: 'Exo Variable' }}>
                      {resultMessage} (confianza en la predicción: {formattedPercentage})
                    </p>

                    <div class="progress-bar">
                      <div class={`${progressClass}`} style={{ width: formattedPercentage }}></div>
                    </div>
                    <p className='subir-text' style={{ fontFamily: 'Exo Variable' }}>Análisis de segmentos de 4 segundos:</p>
                    <div className="progress-bar2">
                      {this.renderChunkSegments()}
                    </div>
                    <audio controls className="audio-container">
                      <source src={`${audioURL}`}  type="audio/mpeg" />
                    </audio>
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer>
            <p>Última actualización del modelo Fastaudio: {lastUpdated}</p>
            
          </footer>

          {isUsabilityFormVisible && (
        <div className="usability-form-popup">
          <div className="usability-form-content">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLScYh3mlAq1MbovNnWMaczz1lIMSTm3VDJi0rZOcbE0yElCu2w/viewform?embedded=true"
              width="700"
              height="520"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >Cargando…</iframe>
            <button onClick={this.closeUsabilityForm}>Cerrar</button>
          </div>
        </div>
      )}
        </div>
      );
    } else {
      // Renderizar el formulario de inicio de sesión
      return (
        <div className="results-container">
          <h2 style={{ fontFamily: 'Exo Variable' }}>Iniciar sesión</h2>
          <form onSubmit={this.handleSubmitLog}>
            <label style={{ fontFamily: 'Exo Variable' }} htmlFor="username">Usuario:</label>
            <input style={{ width: '20%' }} type="text" id="username" onChange={this.handleUsernameChange} required />
  
            <label style={{ fontFamily: 'Exo Variable' }} htmlFor="password">Contraseña:</label>
            <input style={{ width: '20%' }} type="password" id="password" onChange={this.handlePasswordChange} required />
  
            <button type="submit">Iniciar sesión</button>
          </form>
        </div>
        /*<div className="results-container">
          <h2 style={{ fontFamily: 'Exo Variable' }}>En mantenimiento...</h2>
        </div>*/
      );
    }    
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

